<template>
  <div class="container pb-3">
    <template v-if="formsV2Enabled">
      <div class="text-center mt-5">
        <h1 class="mb-4">{{ 'forms.administrativeFormsDisabled' | translate }}</h1>
        <router-link tag="button" to="/dashboard" class="backToDashboard d-none d-md-block">{{
          'general.returnToDashboard' | translate
        }}</router-link>
      </div>
    </template>
    <template v-else>
      <div class="mt-4" v-if="loading || downloading">
        <loader></loader>
      </div>
      <div v-else class="forms no-margin">
        <div class="row mb-2 mt-4">
          <div class="col-12">
            <p class="float-none float-md-right float-lg-right text-center" v-if="data.isClosed">
              <small>{{ 'general.submittedOn' | translate }} {{ data.closeDate }}</small>
            </p>
          </div>
          <hr class="d-block d-md-none" />
        </div>
        <div class="row">
          <fieldset :disabled="data.isClosed || disableResubmitWhenFormsv2Active" class="col-12">
            <form @submit.prevent="next">
              <div class="row">
                <div class="steps-wrapper col-12 col-lg-4" v-if="data.areas.length">
                  <div class="card">
                    <div class="card-body">
                      <el-steps :active="activeStep" :direction="stepDirection" align-center>
                        <el-step
                          @onStepClick="onStepClick(index)"
                          :class="{
                            pointer: index <= stepsFinished,
                            'not-allowed': index > stepsFinished,
                            active: index === activeStep
                          }"
                          :status="
                            index < stepsFinished || data.isClosed
                              ? 'finish'
                              : index === stepsFinished
                              ? 'process'
                              : 'wait'
                          "
                          v-for="(item, index) in data.areas"
                          :key="item.id"
                          :title="item.name"
                        ></el-step>
                      </el-steps>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-8" v-if="current">
                  <div class="mb-4" v-if="current.description">
                    <h6>{{ current.description }}</h6>
                  </div>
                  <div class="area-container">
                    <div
                      class="form-group card area-panel"
                      v-for="(field, index) in current.fields"
                      :class="{ repeatable: field.itemType === 2 }"
                      :key="field.id"
                    >
                      <div
                        class="card-header"
                        v-if="field.fieldName"
                        :id="'label-' + getDashedFieldName(field.fieldName)"
                      >
                        {{ field.fieldName }}
                        <span v-if="isRequired(field)">*</span>
                      </div>
                      <div class="card-body">
                        <form-input
                          :cityResponse="cityResponse"
                          :countryResponse="countryResponse"
                          :data="data"
                          :field="field"
                          :name="field.fieldName"
                          v-model="field.answer"
                          :index="index"
                          :required="isRequired(field)"
                          v-if="field.itemType === 1"
                        />

                        <template v-if="field.itemType === 2">
                          <form-repeatable-area
                            :cityResponse="cityResponse"
                            :countryResponse="countryResponse"
                            :field="field"
                            @refreshRepeat="getData(true)"
                            :data="data"
                            :index="index"
                            :required="isRequired(field)"
                          />
                        </template>
                        <div
                          v-if="field.fieldName"
                          v-show="errors.has(field.fieldName)"
                          class="help text-danger text-danger"
                        >
                          {{ errors.first(field.fieldName) }}
                        </div>
                        <small class="help-block mt-2" v-if="field.helpText">{{ field.helpText }}</small>
                      </div>
                    </div>

                    <div class="row mt-2" v-if="!data.isClosed && !disableResubmitWhenFormsv2Active">
                      <div class="col-md-12">
                        <secondary-button @click="previous" v-if="current.index">{{
                          'quiz.previous' | translate
                        }}</secondary-button>
                        <primary-button class="float-right" type="submit" :loading="loading">
                          <span v-if="activeStep == data.areas.length - 1">{{ 'general.send' | translate }}</span>
                          <span v-else>{{ 'quiz.next' | translate }}</span>
                        </primary-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </fieldset>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// ItemType 1 === Input Field
// ItemType 2 === Repeatable Area

// FieldType 1 === Text or TextArea (Depending on spansMultipleFields)
// FieldType 2 === Select (Single or Multiple Options, Depending on chooseMultipleOptions)
// FieldType 3 === File Upload

import Vue from 'vue';
import { Button, Steps } from 'element-ui';
import Step from '../../components/custom/Step';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import some from 'lodash/some';
import sortBy from 'lodash/sortBy';
import { mapGetters } from 'vuex';

import FormInput from './FormInput';
import FormRepeatableArea from './FormRepeatableArea';

import formMixin from './mixins/form.mixin';

const mqlLG = window.matchMedia('(min-width: 992px)');

export default {
  mixins: [formMixin],
  components: {
    elButton: Button,
    elStep: Step,
    elSteps: Steps,
    FormInput,
    FormRepeatableArea
  },
  created() {
    if (!this.formsV2Enabled) {
      this.getData();
    }
  },
  computed: {
    ...mapGetters('dashboard', ['formsV2Enabled']),
    current() {
      return this.data.areas[this.activeStep] || null;
    },
    isE11() {
      return !!window.MSInputMethodContext && !!document.documentMode;
    },
    disableResubmitWhenFormsv2Active() {
      return this.formsV2Enabled && this.hasAnswers;
    }
  },
  data() {
    return {
      activeStep: 0,
      cityResponse: [],
      countryResponse: [],
      hasAnswers: false,
      data: { areas: [] },
      downloading: false,
      loading: false,
      sending: false,
      stepDirection: mqlLG.matches ? 'vertical' : 'horizontal',
      stepsFinished: 0
    };
  },
  methods: {
    getData(onlyRefreshRepeat) {
      this.$http.get(`dashboard/forms/${this.$route.params.id}`).then(
        (response) => {
          const hasCountryFields = some(response.data.areas, (area) => {
            return find(area.fields, (field) => {
              if (field.itemType === 2) {
                return field.fields.find((f) => {
                  if (f.validator === 10) {
                    return true;
                  }
                });
              } else {
                return field.validator === 10;
              }
            });
          });

          const hasBelgiumZipOrCityFields = some(response.data.areas, (area) => {
            return find(area.fields, (field) => {
              return field.validator === 6 || field.validator === 5;
            });
          });

          if (!response.data.isClosed && hasBelgiumZipOrCityFields && !this.cityResponse.length) {
            this.loading = true;
            this.$http.get('forms/belgiancities').then((response) => {
              this.cityResponse = response.data.cities;
              this.loading = false;
            });
          }

          if (!response.data.isClosed && hasCountryFields && !this.countryResponse.length) {
            this.loading = true;
            this.$http.get('forms/countries').then(({ data }) => {
              this.countryResponse = data.countries;
              this.loading = false;

              response.data.areas.forEach((area) => {
                area.fields.forEach((field) => {
                  if (field.validator === 10 && field.answer) {
                    // country {
                    const found = find(this.countryResponse, { iso: field.answer });
                    if (found) {
                      field.answer = found.name;
                    }
                  }
                  if (field.itemType === 2) {
                    field.fields.forEach((f) => {
                      if (f.validator === 10 && field.answer) {
                        const found = find(this.countryResponse, { iso: field.answer });
                        if (found) {
                          field.answer = found.name;
                        }
                      }
                    });
                  }
                });
              });
            });
          }

          const formatField = (field) => {
            if (field.fieldName) {
              field.fieldName = field.fieldName.replace(/\*/g, '');
              field.fieldName = field.fieldName.replace(/:/g, '');
            }

            if (field.fieldType === 2) {
              if (field.chooseMultipleOptions) {
                field.answers = field.options.filter((item) => {
                  return field.answers.indexOf(item.id) >= 0;
                });
              } else {
                field.answer = find(field.options, (item) => {
                  return item.id == field.answers[0];
                });
                field.answers = field.answer;
              }
            }

            if (field.spansMultipleFields) field.type = 'textarea';
            switch (field.validator) {
              case 2:
                field.validate = 'dutchId';
                break;
              case 3:
                field.validate = 'belgianId';
                break;
              case 4:
                // field.validate = "phoneNumber";
                field.validate = '';
                break;
              case 5:
                field.validate = 'belgianZip';
                break;
              // case 6: field.validate = 'belgianCity'; break;
              case 7:
                field.mask = '11/11/1111';
                field.validate = '';
                break;
              case 8:
                field.validate = 'iban';
                field.mask = '';
                break;
              case 9:
                field.validate = 'bic';
                field.mask = '';
                break;
              case 10:
                field.validate = 'country';
                break;
              case 11:
                field.validate = 'email';
                field.mask = '';
                field.type = 'email';
                break;
              default:
                field.validate = '';
                field.mask = '';
                break;
            }

            if (field.textFormat) {
              field.mask = field.textFormat // set mask if user has defined it
                .replace(/([\d])/g, '1');
            }

            if (this.isRequired(field)) {
              if (!field.validate || field.validate === '') {
                field.validate = 'required';
              } else {
                field.validate = `required|${field.validate}`;
              }
            }
          };

          response.data.areas.forEach((area, index) => {
            area.index = index;
            area.fields = sortBy(area.fields, ['positionId']); // sort by position id
            area.fields.forEach((field) => {
              formatField(field);
              if (field.itemType === 2) {
                field.fields.forEach((f) => {
                  formatField(f);
                });
              }
            });
          });

          if (response.data.isClosed) {
            response.data.closeDate = new Date(response.data.closeDate).toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric'
            }); //moment(response.data.closeDate).format("DD/MM/YYYY");
            this.stepsFinished = response.data.areas.length;
          }

          if (onlyRefreshRepeat) {
            this.data.areas = response.data.areas.map((area, areaIndex) => {
              area.fields = area.fields.map((field, fieldIndex) => {
                if (field.itemType === 2) {
                  return field;
                }
                return this.data.areas[areaIndex].fields[fieldIndex];
              });
              return area;
            });
          } else {
            this.data = response.data;
          }

          response.data.areas.forEach((area, index) => {
            area.fields.forEach((field) => {
              if (!this.hasAnswers && field.answer !== null && field.answer !== undefined) {
                this.hasAnswers = true;
              }
            });
          });
        },
        (err) => {
          this.$notify({
            type: 'error',
            message: this.$t('notifications.error'),
            position: 'bottom-right'
          });
        }
      );
    },
    previous() {
      this.activeStep = Math.max(0, this.activeStep - 1);
    },
    next() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          if (this.$validator.errors && this.$validator.errors.items && this.$validator.errors.items.length) {
            const firstError = this.$validator.errors.items[0];
            if (firstError && firstError.field) {
              const el = document.querySelector(`#label-${this.getDashedFieldName(firstError.field)}`);
              if (el) {
                el.scrollIntoView();
              }
              if (firstError.msg) {
                return this.$notify({
                  type: 'warning',
                  message: firstError.msg,
                  position: 'bottom-right'
                });
              }
            }
          }
          return this.$notify({
            type: 'warning',
            message: this.$t('form.errors'),
            position: 'bottom-right'
          });
        } else {
          let errorField = false;
          this.data.areas[this.activeStep].fields.forEach((field) => {
            if (field.fieldType === 3) {
              if (field.isRequired && !field.FileData && !field.FileExtension && !(field.FileName || field.fileName)) {
                errorField = field;
              }
            }
          });

          if (errorField) {
            const el = document.querySelector(`#label-${this.getDashedFieldName(errorField.fieldName)}`);
            if (el) {
              el.scrollIntoView();
            }
            return this.$notify({
              type: 'warning',
              message: this.$t('notifications.isRequired', { field: errorField.fieldName }),
              position: 'bottom-right'
            });
          }

          const isFinal = this.activeStep == this.data.areas.length - 1;

          const fields = [];

          // only send data that we need...
          cloneDeep(this.current.fields).forEach((field) => {
            if (field.itemType === 2) return;
            if (field.fieldType === 3 && !field.FileData) return;
            const obj = {
              id: field.id
            };

            if (field.fieldType === 1) {
              obj.answer = field.answer;
            }

            if (field.answers && field.fieldType === 2 && !field.chooseMultipleOptions) {
              obj.answer = field.answers.id;
              obj.answers = [field.answers.id];
            }

            if (field.answers && field.answers.length && field.chooseMultipleOptions) {
              obj.answers = field.answers.map((i) => i.id);
            }

            if (field.fieldType === 3) {
              delete obj.answer;
              obj.FileData = field.FileData;
              obj.FileExtension = field.FileExtension;
              obj.FileName = field.FileName;
            }

            if (field.validator === 10) {
              const found = find(this.countryResponse, { name: field.answer });
              if (found) {
                obj.answer = found.iso;
              }
            }

            fields.push(obj);
          });

          if (window.SALES) {
            if (isFinal) {
              alert('Not available in demo');
              return this.$router.push('dashboard');
            } else {
              return this.activeStep++;
            }
          }

          this.loading = true;
          Vue.prototype.$http
            .post(
              `dashboard/forms/${this.$route.params.id}/area`,
              {
                id: this.$route.params.id,
                fields,
                isFinal
              },
              {
                timeout: 60000
              }
            )
            .then(
              () => {
                this.loading = false;

                if (isFinal) {
                  return this.$confirm(this.$t('form.thanks'), {
                    confirmButtonText: this.$t('general.returnToDashboard'),
                    cancelButtonText: this.$t('form.downloadPdf'),
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    showClose: false,
                    type: 'info'
                  })
                    .then(() => {
                      this.$router.push({ name: 'dashboard' });
                    })
                    .catch(() => {
                      this.downloading = true;
                      this.$http(`dashboard/forms/${this.$route.params.id}/pdf`).then(
                        (response) => {
                          window.open(response.data.pdfUrl, '_blank');
                          this.downloading = false;
                          this.$router.push({ name: 'dashboard' });
                        },
                        () => {
                          this.$notify({
                            type: 'error',
                            message: this.$t('notifications.error'),
                            position: 'bottom-right'
                          });
                          this.downloading = false;
                          this.$router.push({ name: 'dashboard' });
                        }
                      );
                    });
                }

                this.activeStep = Math.min(this.data.areas.length - 1, this.activeStep + 1);
                this.stepsFinished = Math.max(this.stepsFinished, this.activeStep);
                window.scrollTo(0, 0);
              },
              ({ response }) => {
                if (response && response.data && response.data.errors && response.data.errors.length) {
                  const { message } = response.data.errors[0];
                  this.$notify({
                    type: 'error',
                    message,
                    position: 'bottom-right'
                  });
                  const id = this.getDashedFieldName(message.substring(0, message.indexOf('is')).trim());
                  const el = document.querySelector(`#label-${id}`);
                  if (el) {
                    el.scrollIntoView();
                  }
                }
                this.loading = false;
              }
            );

          return;
        }
      });
    },

    onStepClick(index) {
      if (index > this.stepsFinished) return;
      this.activeStep = index;
    },
    checkMqlMD($event) {
      this.stepDirection = $event.matches ? 'vertical' : 'horizontal';
    }
  },
  mounted() {
    mqlLG.addListener(this.checkMqlMD); // Add the callback function as a listener to the query list.
  },
  beforeDestroy() {
    mqlLG.removeListener(this.checkMqlMD);
  }
};
</script>

<style lang="scss" scoped>
.area-container {
  .panel:not(.repeatable) {
    margin-bottom: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: -1px;
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
  }

  .panel.repeatable {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  margin-bottom: 20px;
}

.backToDashboard {
  margin-left: auto;
  margin-right: auto;

  white-space: nowrap;
  border: unset;
  border-radius: 4px;
  font-weight: 500;
  line-height: 13px;
  height: 37px;
  padding: 0 16px;
  cursor: pointer;
}
</style>

<style lang="scss">
.steps-wrapper {
  margin-bottom: 36px;

  .card .card-body {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      height: calc(100vh - 100px);
    }
  }
}

.el-step.active .el-step__main {
  text-decoration: underline;
}

hr.divider {
  margin-left: -15px;
  margin-right: -15px;
}
</style>
