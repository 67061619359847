<template>
  <div v-if="loading">
    <loader />
  </div>
  <div v-else>
    <h5 class="font-weight-bold">
      {{ field.name }}
      <i class="material-icons pointer float-right" v-if="hasAnswers && !adding && !data.isClosed" @click="onAdd"
        >add</i
      >
    </h5>
    <hr />
    <div v-for="(item, guid) in field.answers" :key="'item-' + guid">
      <div class="close-btn float-right pointer" v-if="!data.isClosed" @click="remove($event, guid)">
        <i class="material-icons">close</i>
      </div>
      <blockquote>
        <div class="form-group" v-for="(answer, answerIndex) in item" :key="'answer-' + answerIndex">
          <label class="font-weight-bold" :id="'label-' + getDashedFieldName(field.fields[answerIndex])"
            >{{ field.fields[answerIndex].fieldName }}
          </label>
          <template v-if="!answer.fileName">
            <p
              v-if="field.fields[answerIndex].fieldType === 2"
              v-html="
                getOptionsAnswer(field.fields[answerIndex].id, field.fields[answerIndex].options, field.answers[guid])
              "
            ></p>
            <p
              v-else
              v-html="
                getTextAnswer(field.fields[answerIndex].id, field.answers[guid], field.fields[answerIndex].validator)
              "
            ></p>
          </template>
          <p v-if="answer.fileName !== null">
            {{ answer.fileName }}
          </p>
        </div>
      </blockquote>
    </div>
    <hr v-if="field.answers && field.answers.length" />
    <template v-if="!hasAnswers || adding">
      <div class="form-group" v-for="(f, fieldIndex) in field.fields" :key="f.id">
        <label :id="'label-' + getDashedFieldName(f.fieldName)">
          {{ f.fieldName }}
          <span v-if="isRequired(f)">*</span>
        </label>
        <form-input
          :cityResponse="cityResponse"
          :countryResponse="countryResponse"
          :data="data"
          :field="f"
          :name="f.fieldName | dash | cleanSpecialChars"
          v-model="f.answer"
          :index="fieldIndex"
          v-if="f.itemType === 1"
          :required="isRequired(f)"
        />
        <div
          v-if="f.fieldName && f.validate && f.validate.length && errors.has(getDashedFieldName(f.fieldName))"
          class="help text-danger"
        >
          {{ errors.first(getDashedFieldName(f.fieldName)) }}
        </div>
        <span class="help-block" v-if="f.helpText">{{ f.helpText }}</span>
      </div>
    </template>
    <div class="text-right" v-if="!hasAnswers || (hasAnswers && adding)">
      <secondary-button :disabled="noValues" @click="save">{{ 'general.save' | translate }}</secondary-button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import formMixin from './mixins/form.mixin';
import FormInput from './FormInput';
import some from 'lodash/some';
import find from 'lodash/find';

export default {
  components: {
    FormInput
  },
  mixins: [formMixin],
  computed: {
    hasAnswers() {
      return !!Object.keys(this.field.answers).length;
    },
    noValues() {
      return !some(this.field.fields, (field) => {
        return !!field.answer || !!field.FileData;
      });
    }
  },
  data() {
    return {
      activeName: 0,
      adding: false,
      loading: false,
      selectedUploadField: null
    };
  },
  props: {
    countryResponse: {
      type: [Object, Array],
      default: () => []
    },
    cityResponse: {
      type: [Object, Array],
      default: () => []
    },
    data: {
      required: true,
      type: [Object, Array]
    },
    field: {
      type: Object,
      required: true,
      default: {}
    },
    index: {
      required: true,
      type: Number
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onAdd() {
      this.field.fields.forEach((f) => {
        f.answer = null;

        if (f.fieldType === 3) {
          // f.fileName = null
        }
      });
      this.adding = true;
    },
    remove(event, guid) {
      this.loading = true;
      this.$http
        .delete(`/dashboard/forms/${this.$route.params.id}/repeatingarea/${guid}`)
        .then((response) => {
          delete this.field.answers[guid];
          this.$forceUpdate();
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          this.$notify({
            type: 'error',
            message: this.$t('notifications.error'),
            position: 'bottom-right'
          });
        });
    },
    save() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          if (this.$validator.errors && this.$validator.errors.items && this.$validator.errors.items.length) {
            const firstError = this.$validator.errors.items[0];
            if (firstError && firstError.field) {
              const el = document.querySelector(`label[id=label-${firstError.field}]`);
              if (el) {
                el.scrollIntoView();
              }
              if (firstError.msg) {
                return this.$notify({
                  type: 'warning',
                  message: firstError.msg,
                  position: 'bottom-right'
                });
              }
            }
          }
          return this.$notify({
            type: 'warning',
            message: this.$t('form.errors'),
            position: 'bottom-right'
          });
        } else {
          this.loading = true;
          this.$http;

          const fields = this.field.fields.map((field) => {
            if (field.fieldType === 2 && field.answers) {
              if (Array.isArray(field.answers)) {
                field.answers = field.answers.map((answer) => answer.id);
              } else {
                field.answers = [field.answers.id];
              }
            }
            if (field.validator === 10) {
              const found = find(this.countryResponse, { name: field.answer });
              if (found) {
                field.answer = found.iso;
              }
            }

            return field;
          });

          let data = {
            id: parseInt(this.$route.params.id),
            fields: fields,
            groupId: '00000000-0000-0000-0000-000000000000'
          };
          const user = JSON.parse(window.localStorage.user);

          Vue.prototype.$http
            .post(`/dashboard/forms/${this.$route.params.id}/repeatingarea?languageId=${user.languageId}`, data, {
              timeout: 60000
            })
            .then((response) => {
              this.field.fields.forEach((f) => {
                f.answer = null;
              });
              this.adding = false;
              this.$forceUpdate();
              this.$emit('refreshRepeat');
              this.loading = false;
            })
            .catch((err) => {
              this.loading = false;
              this.$notify({
                type: 'error',
                message: this.$t('notifications.error'),
                position: 'bottom-right'
              });
            });
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        }
      });
    },
    handleUploadChange(file, fileList) {
      this.field.fileList = fileList;
      this.$forceUpdate();
      fileReader.onloadend = () => {
        this.field.FileData = fileReader.result;
        this.field.FileExtension = '.' + file.name.split('.').pop();
        this.field.FileName = file.name;
      };
      fileReader.readAsDataURL(file.raw);
    },
    handleUploadRemove(file, fileList) {
      this.field.fileList = fileList;
      this.field.FileData = null;
      this.field.FileExtension = null;
      this.field.FileName = null;
      this.$forceUpdate();
    },
    getTextAnswer(id, answers, fieldValidator) {
      if (!answers || !id) {
        return false;
      }
      const countryResponse = this.countryResponse;
      const answer = answers.find((answer) => answer.id === id);
      if (answer) {
        if (fieldValidator === 10) {
          const found = find(countryResponse, { iso: answer.answer });
          if (found) {
            answer.answer = found.name;
          }
        }
        return answer.answer;
      }
      return false;
    },
    getOptionsAnswer(id, options, answers) {
      if (!answers || !options || !id) {
        return false;
      }

      const answer = answers.find((answer) => answer.id === id);
      if (answer) {
        return options
          .filter((option) => answer.answers.indexOf(option.id) > -1)
          .map((answer) => answer.name)
          .join(', ');
      }
      return false;
    }
  }
};
</script>

<style lang="scss">
@import '~vue-multiselect/dist/vue-multiselect.min.css';
.multiselect__tags input {
  box-shadow: none !important;
}
.el-upload__input {
  display: none !important;
}
.el-upload-list {
  @media (min-width: 772px) {
    max-width: 70%;
  }
}
</style>

<style lang="scss" scoped>
blockquote {
  font-size: var(--text-size-small);
}

div.divider {
  padding: 1px;
  margin: 1px 0;
  .content {
    border-top: 2px solid #eeeeee;
    border-bottom: 2px solid #eeeeee;
    height: 12px;
  }

  background-color: #f9f9f9;
  height: 12px;
  z-index: 3000;
  margin-left: -16px;
  margin-right: -16px;
}
</style>
